.page-transition-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  
  .page-transition-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 500ms, transform 500ms;
  }
  
  .page-transition-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  
  .page-transition-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms, transform 500ms;
  }
  