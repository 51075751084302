#home-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
#home-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#engagement {
    display: flex;
    align-items: center;
    justify-content: center; 
}
#language {
    display: flex;
    align-items: center;
    justify-content: center;
}
#auth-type {
    display: flex;
    align-items: center;
    justify-content: center;
}
#social-container, #social-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#social-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.social-icon {
    max-height: 50px;
    max-width: 50px;
    min-height: 50px;
    min-width: 50px;
}
.social-icon-button {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
}

#name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.name {
    margin-left: 2;
    margin-right: 2;
}
.group {
    margin: 5px;
}
#birth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.check {
    display: flex;
    align-items: center;
    justify-content: center;
}